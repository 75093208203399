import React, { useState } from 'react';

import './ForGyms.css';

import ComputerIphone from '../../assets/images/Computer-phone.png';
import { Colors } from '../../constants';

const ForGyms = () => {
    const handleDemoRequestClick = () => window.location.href = '/for-gyms/request-demo';

    return (
        <div style={{display: 'flex', flex: 1}}>
            <div className='mainForGymsContainer'>
                <div className='forGymsDescriptionContainer'>
                    <p className='forGymTitle'>ClimbTime<br/>for your gym</p>
                    <ul className='benefitList'>
                        <li className='forGymBenefit'>Increase membership retention</li>
                        <li className='forGymBenefit'>Spread your gym’s news</li>
                        <li className='forGymBenefit'>Promote events & classes</li>
                        <li className='forGymBenefit'>Host gym clubs/leagues</li>
                        <li className='forGymBenefit'>Manage your routesetting</li>
                        <li className='forGymBenefit'>Track member feedback</li>
                    </ul>
                    <button
                        style={{backgroundColor: Colors.MUSTARD, border: 'none', borderRadius: 30, fontSize: 16, fontWeight: 600, width: '60%', marginBottom: '50px', padding: '10px 0px 10px 0px'}}
                        onClick={handleDemoRequestClick}
                    >
                        Request a demo!
                    </button>
                </div>
                <img
                    alt='Computer and Iphone'
                    className='computerIphoneImage'
                    src={ComputerIphone}
                />
            </div>
        </div>
    )
}

export default ForGyms;
