import React from 'react';

import './DemoVideosPage.css';

const DemoVideosPage = () => {
    return (
        <div>
            <p className='demoVideosTitle'>
                Demo Videos
            </p>

            <p className='demoVideoDescription'>
                Full App Demo (4 min)
            </p>
            <div className='appFeaturesVideo'>
                <iframe
                    width="100%"
                    height="100%"
                    src="https://www.youtube.com/embed/3Eh4plTzbKA"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                />
            </div>

            <p className='demoVideoDescription'>
                Gym Admin Portal
            </p>
            <div className='gymAdminVideo'>
                <iframe
                    width="100%"
                    height="100%"
                    src="https://www.youtube.com/embed/Ekllo8uHYGM"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                />
            </div>

            <p className='demoVideoDescription'>
                Route Setting
            </p>
            <div className='routeSettingVideo'>
                <iframe
                    width="100%"
                    height="100%"
                    src="https://www.youtube.com/embed/zEF2XnJHIF8"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                />
            </div>

            <p className='demoVideoDescription'>
                Clubs
            </p>
            <div className='appClubVideo marginBottom'>
                <iframe
                    width="100%"
                    height="100%"
                    src="https://www.youtube.com/embed/UtvAKQlIIqw"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                />
            </div>
        </div>
    )
}

export default DemoVideosPage;
