import React, { Fragment } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import {isAndroid, isIOS} from 'react-device-detect';

import Main from './main/Main';
import Download from './download/Download';
import About from './about/About';
import ContactUs from './contactUs/ContactUs';
import PrivacyPolicy from './privacyPolicy/PrivacyPolicy';
import TopBanner from './molecules/topBanner/TopBanner';
import BottomBanner from './molecules/bottomBanner/BottomBanner';
import ForGyms from './forGyms/ForGyms';
import RequestDemoPage from './forGyms/RequestDemoPage';
import DemoVideosPage from './forGyms/DemoVideosPage';
import FallSpecialForm from './forGyms/FallSpecialForm';

const MobileDownloadRedirect = () => {
  if (isAndroid) {
    window.location.href = "https://play.google.com/store/apps/details?id=com.climbtime&hl=en_US&gl=US";
  } else if (isIOS) {
    window.location.href = "https://apps.apple.com/ug/app/climbtime/id1571861196";
  } else {
    window.location.href = "https://climb-time.com/download";
  }

  return null;
}

const App = () => {
  return (
    <Fragment>
      <div style={{display: 'flex', flexDirection: 'column', flex: 1, minHeight: '95vh'}}>
        <TopBanner />

        <Router>
            <Switch>
              <Route
                exact
                path='/'
                component={Main}
              />
              <Route
                exact
                path='/download'
                component={Download}
              />
              <Route
                exact
                path='/download/mobile'
                component={MobileDownloadRedirect}
              />
              <Route
                path='/about'
                component={About}
              />
              <Route
                path='/contact-us'
                component={ContactUs}
              />
              <Route
                path='/privacy-policy'
                component={PrivacyPolicy}
              />
              <Route
                exact
                path='/for-gyms'
                component={ForGyms}
              />
              <Route
                path='/for-gyms/request-demo'
                component={RequestDemoPage}
              />
              <Route
                path='/for-gyms/special-fall-offer'
                component={FallSpecialForm}
              />
              <Route
                path='/for-gyms/demo-videos'
                component={DemoVideosPage}
              />
            </Switch>
        </Router>
      </div>

      <BottomBanner />
    </Fragment>
  );
}

export default App;
