import React from 'react';

import './ContactUs.css';

const ContactUs = () => {

    return (
        <div>
            <h2 style={{fontSize: 'calc(12px + 0.8vw)', textAlign: 'center', marginTop: '5vh', padding: '2vw'}}>
                We would love to hear from you! Please contact us at the following:
            </h2>
            <div className='contactCardsContainer'>
                <div className='contactCard'>
                    <h3>Email:</h3>
                    <p style={{margin: 0}}>info@climb-time.com</p>
                </div>

                <div className='contactCard'>
                    <h3>Phone Number:</h3>
                    <p style={{margin: 0}}>(717)440-7702</p>
                </div>
            </div>
        </div>
    )
}

export default ContactUs;
