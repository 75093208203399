import React from 'react';

import './TopBanner.css';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import Logo from '../../../assets/images/Logo.png';

const TopBanner = () => {

    return (
        <Navbar collapseOnSelect expand='md' className="nav">
            <Navbar.Toggle aria-controls='responsive-navbar-nav' />
            <a href="/" className='logo'>
                <img alt='Logo' src={Logo} style={{height:'100%'}}></img> 
            </a>
            <a href="https://gym.climb-time.com" className='gymLogin'>
                Gym Login
            </a>
            <Navbar.Collapse id='responsive-navbar-nav'>
                <Nav style={{margin: 'auto'}}>
                    <a href="/download" className='links'>
                        Download
                    </a>
                    <a href="/about" className='links'>
                        About
                    </a>
                    <div className='navMenu'>
                        <a href="/for-gyms" className='links'>
                            For Gyms
                        </a>
                        <div className='navSubMenu'>
                            <a href='/for-gyms' className='submenuLink firstSubLink'>
                                Why choose ClimbTime
                            </a>
                            <a href='/for-gyms/request-demo' className='submenuLink'>
                                Request a Demo
                            </a>
                            <a href='/for-gyms/demo-videos' className='submenuLink lastSubLink'>
                                View Demo Videos 
                            </a>
                        </div>
                    </div>
                    <a href='/for-gyms/request-demo' className='hidden links'>
                        Request a Demo
                    </a>
                    <a href='/for-gyms/demo-videos' className='hidden links'>
                        View Demo Videos 
                    </a>
                    <a href="/contact-us" className='links'>
                        Contact Us
                    </a>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default TopBanner;
