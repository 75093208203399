import React from 'react';

import './About.css';

import Climber from '../../assets/images/Climber.png';
import AboutBackground from '../../assets/images/AboutBackground.png';

const About = () => {

    return (
        <div style={{display:'flex', flexDirection:'column', backgroundColor: '#F4F3F4'}}>
            <div className='aboutContainer'>
                <img alt='climber' src={Climber} className='climberImage' />
                <img alt='climb time background' src={AboutBackground} className='aboutImage' />
                <div className='aboutFeatures'>
                    <p className='climbTimeDescription'>
                        ClimbTime makes it easy to find new partners, join clubs, and see classes and events, all at YOUR specific gym! Making friends and getting involved with all the fun at your gym has never been easier! On top of this, you can comment on and log every climb in your gym while seeing a live layout of your gym's routes.
                    </p>

                    <p className='featureHeading'>View Your Gym</p>
                    <p className='featureDescription'>
                        ClimbTime has a live map of your gym that is updated every new set! Here you can comment, rate, and log each problem! You can also see what your friends think of the problem!
                    </p>

                    <p className='featureHeading'>Finding Partners</p>
                    <p className='featureDescription'>
                        Stop using the bulletin board at your gym and see ClimbTime's live list of users at the gym!!! View partner listings and message them to start climbing together! Looking for more consistent partners for the future? Use our filters to find your perfect climbing partner!
                    </p>

                    <p className='featureHeading'>Join Clubs</p>
                    <p className='featureDescription'>
                        Clubs of all kinds are so easy to join on ClimbTime! See all club updates and events in one easy-to-use source. Make your own clubs as well!
                    </p>

                    <p className='featureHeading'>Gym Updates</p>
                    <p className='featureDescription'>
                        See all of your gym's news, classes, and events, on ClimbTime! It's the easiest way to stay updated on a new problem set, sign up for a belay class, and RSVP for a climbing movie night! 
                    </p>

                    <p className='featureHeading'>Logging</p>
                    <p className='featureDescription'>
                        ClimbTime helps you log all of your gym sends and helps you track your progress. See a list of all completed problems including the number of attempts. ClimbTime also gives your progress charts to see your improvement.
                    </p>

                    <p className='featureHeading'>Friends</p>
                    <p className='featureDescription'>
                        Instead of a billion random phone numbers, keep all your climbing friends in ClimbTime! See a list of all your friends and easily message them. This also helps enable privacy features where only your friends can see your content, if you so choose.
                    </p>

                    <p className='featureHeading'>AND MUCH MORE!</p>
                    <p className='featureDescription'>
                        Join the ClimbTime community today :)
                    </p>
                </div>
            </div>
        </div>
    )
}

export default About;
