import React from 'react';

import './DownloadBox.css';

import AppIcon from '../../../assets/images/AppIcon.png';
import AppStore from '../../../assets/images/AppStore.png';
import GooglePlay from '../../../assets/images/GooglePlay.png';

const DownloadBox = () => {

    return(
        <div style={{marginTop: 50, marginBottom: 30, display:'flex', flexDirection: 'column', alignSelf: 'center', alignItems: 'center'}}>
            <p style={{fontSize: 'calc(18px + 2vw)', fontWeight: 400, margin: 10}}>
                Download for iOS/Android.
            </p>
            <img alt='AppIcon' src={AppIcon} />
            <div style={{display:'flex', marginTop: 10}}>
                <a href="https://apps.apple.com/ug/app/climbtime/id1571861196" className='stores'> 
                    <img alt='AppStore'src={AppStore} style={{width:'100%', height:'100%'}} />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.climbtime&hl=en_US&gl=US" className='stores'> 
                    <img alt='GooglePlay' src={GooglePlay} style={{width:'100%', height:'100%'}} />
                </a>
            </div>
        </div>
    )
}

export default DownloadBox;
