import React from 'react';

import './Download.css';

import DownloadBox from '../molecules/downloadBox/DownloadBox';

const Download = () => {
    
    return (
        <div>
            {/* <h2 className='bar'>ClimbTime is available on both the Apple App Store and Google Play Store!</h2> */}
            <div style = {{display:'flex', justifyContent:'space-around', width:'100%', marginTop:'50px'}}>
                <DownloadBox />
            </div>
        </div>
    )
}

export default Download;