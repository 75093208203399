import React, { useEffect, useRef, useState } from 'react';
import {isAndroid, isIOS} from 'react-device-detect';

import './Main.css';

import IPhone1 from '../../assets/images/IPhone-1.png';
import IPhone2 from '../../assets/images/IPhone-2.png';
import IPhone3 from '../../assets/images/IPhone-3.png';
import IPhone4 from '../../assets/images/IPhone-4.png';
import IPhone5 from '../../assets/images/IPhone-5.png';

import GymLogo1 from '../../assets/images/gym-logo-1.png';
import GymLogo2 from '../../assets/images/gym-logo-2.png';
import GymLogo3 from '../../assets/images/gym-logo-3.png';
import GymLogo4 from '../../assets/images/gym-logo-4.png';
import GymLogo5 from '../../assets/images/gym-logo-5.png';
import GymLogo6 from '../../assets/images/gym-logo-6.png';
import GymLogo7 from '../../assets/images/gym-logo-7.png';
import GymLogo8 from '../../assets/images/gym-logo-8.png';
import GymLogo9 from '../../assets/images/gym-logo-9.png';
import GymLogo10 from '../../assets/images/gym-logo-10.png';
import GymLogo11 from '../../assets/images/gym-logo-11.png';
import GymLogo12 from '../../assets/images/gym-logo-12.png';
import GymLogo13 from '../../assets/images/gym-logo-13.png';
import GymLogo14 from '../../assets/images/gym-logo-14.png';

import Book from '../../assets/images/Book.png';
import Carabiner from '../../assets/images/Carabiner.png';
import Map from '../../assets/images/Map.png';
import Newspaper from '../../assets/images/Newspaper.png';
import People from '../../assets/images/People.png';
import TransparentBackground from '../../assets/images/GymBackgroundImage.png';
import { Colors } from '../../constants';

const Main = () => {
    const [imageNum, setImageNum] = useState<number>(0);
    const timeoutIdRef = useRef<any>(undefined);

    useEffect(() => {
        timeoutIdRef.current = setTimeout(() => {
            setImageNum(imageNum + 1);
        }, 2500);
    }, [imageNum]);

    const handleDownloadClick = () => {
        if (isAndroid) {
            window.location.href = "https://play.google.com/store/apps/details?id=com.climbtime&hl=en_US&gl=US";
        } else if (isIOS) {
            window.location.href = "https://apps.apple.com/ug/app/climbtime/id1571861196";
        } else {
            window.location.href = "https://climb-time.com/download";
        }
    };

    const IPhoneScreen =
        imageNum % 5 === 0 ? IPhone1 :
        imageNum % 5 === 1 ? IPhone2 :
        imageNum % 5 === 2 ? IPhone3 :
        imageNum % 5 === 3 ? IPhone4 :
        IPhone5;

    return (
        <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
            <div className='mainContainer'>
                <div className='iphoneWithButtonsContainer'>
                    <img
                        alt='IPhone Screen 1'
                        className='iphoneImage'
                        src={IPhoneScreen}
                    />
                    <div className='indexButtonContainer'>
                        {[0, 1, 2, 3, 4].map(num => {
                            const handleNumClick = () => {
                                if (timeoutIdRef.current) {
                                    clearTimeout(timeoutIdRef.current);
                                }
                                setImageNum(num);
                            }
                            return (
                                <button
                                    key={num}
                                    name='slide'
                                    onClick={handleNumClick}
                                    style={{
                                        border: '1px solid #dedede',
                                        backgroundColor: imageNum % 5 === num ? Colors.MUSTARD : Colors.LTLTGREY,
                                        borderRadius: 25,
                                        height: 12,
                                        width: 12,
                                        margin: 5,
                                        padding: 0,
                                        cursor: 'pointer',
                                    }}
                                />
                            )
                        })}
                    </div>
                </div>
                <div className='sloganContainer'>
                    <div className='sloganPhrase'>
                        <p className='sloganWord'>
                            Community.
                        </p>
                        <p className='sloganWord'>
                            Starts.
                        </p>
                        <p className='sloganWord'>
                            Here.
                        </p>
                    </div>
                    <button
                        className='downloadButton'
                        onClick={handleDownloadClick}
                    >
                        Get the ClimbTime App
                    </button>

                    <div style={{display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'flex-end', marginBottom: '7vh'}}>
                        <p style={{fontWeight: 'lighter', fontSize: 24, marginBottom: 0, marginTop: '5%', marginLeft: 5, textAlign: 'center'}}>Currently Hosted In:</p>
                        <div className='gymLogosContainer'>
                            <img alt='Gym Logo 12' className='gymLogo' src={GymLogo12} />
                            <img alt='Gym Logo 8' className='gymLogo' src={GymLogo8} />
                            <img alt='Gym Logo 1' className='gymLogo' src={GymLogo1} />
                            <img alt='Gym Logo 6' className='gymLogoLarge' src={GymLogo6} />
                            <img alt='Gym Logo 10' className='gymLogo' src={GymLogo10} />
                            <img alt='Gym Logo 4' className='gymLogo' src={GymLogo4} />
                            <img alt='Gym Logo 7' className='gymLogo' src={GymLogo7} />
                            <img alt='Gym Logo 3' className='gymLogo' src={GymLogo3} />
                            <img alt='Gym Logo 11' className='gymLogo' src={GymLogo11} />
                            <img alt='Gym Logo 5' className='gymLogo' src={GymLogo5} />
                            <img alt='Gym Logo 9' className='gymLogo' src={GymLogo9} />
                            <img alt='Gym Logo 2' className='gymLogo' src={GymLogo2} />
                            <img alt='Gym Logo 13' className='gymLogo' src={GymLogo13} />
                            <img alt='Gym Logo 14' className='gymLogo' src={GymLogo14} />
                        </div>
                    </div>
                </div>
            </div>

            <div style={{display: 'flex', justifyContent: 'center', marginTop: '2.5vh', backgroundImage: `url(${TransparentBackground})`, backgroundSize:'cover',}}>
                <div className='allFeaturesContainer'>
                    <div className='featureContainer'>
                        <img alt='book' src={Map} className='featureDescriptorIcon' />
                        <p className='featureDescriptor'>Explore your gym</p>
                    </div>
                    <div className='featureContainer end'>
                        <img alt='book' src={Carabiner} className='featureDescriptorIcon' />
                        <p className='featureDescriptor'>Find & connect with new partners</p>
                    </div>
                    <div className='featureContainer'>
                        <img alt='book' src={People} className='featureDescriptorIcon' />
                        <p className='featureDescriptor'>Join clubs at your gym</p>
                    </div>
                    <div className='featureContainer end'>
                        <img alt='book' src={Newspaper} className='featureDescriptorIcon' />
                        <p className='featureDescriptor'>See your gym's latest news</p>
                    </div>
                    <div className='featureContainer last'>
                        <img alt='book' src={Book} className='featureDescriptorIcon' />
                        <p className='featureDescriptor'>Log your climbs</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Main;
