import React, { useState } from 'react';

import TextField from "@material-ui/core/TextField";

import './RequestDemoPage.css';

import db from '../../firebase.config';
import { Colors } from '../../constants';

interface IRequestDemoFormErrors {
    firstName?: string;
    lastName?: string;
    role?: string;
    email?: string;
    gymName?: string;
    gymLocation?: string;
    comments?: string;
}

const demoRequestsRef = db.collection('demoRequests');

const RequestDemoPage = () => {
    const [requestForm, setRequestForm] = useState<{[key: string]: string}>({
        firstName: '',
        lastName: '',
        role: '',
        email: '',
        gymName: '',
        gymLocation: '',
        comments: '',
    });
    const [errors, setErrors] = useState<IRequestDemoFormErrors>({});
    const [isThankYouOpen, setIsThankYouOpen] = useState<boolean>(false);

    const handleCloseDemoRequest = () => window.location.href = '/for-gyms';

    const handleUpdateFormValue = (e: any) => {
        const requestFormCopy: {[key: string]: string} = {...requestForm};
        requestFormCopy[e.target.name] = e.target.value;
        setRequestForm(requestFormCopy);
    }

    const handleSubmitRequestDemo = async () => {
        const errorsCopy = {...errors};
        if (requestForm.firstName === '') {
            errorsCopy.firstName = '*Required';
        }
        if (requestForm.lastName === '') {
            errorsCopy.lastName = '*Required';
        }
        if (requestForm.role === '') {
            errorsCopy.role = '*Required';
        }
        if (requestForm.email === '') {
            errorsCopy.email = '*Required';
        }
        if (requestForm.gymName === '') {
            errorsCopy.gymName = '*Required';
        }
        if (requestForm.gymLocation === '') {
            errorsCopy.gymLocation = '*Required';
        }
        if (Object.keys(errorsCopy).length > 0) {
            setErrors(errorsCopy);
            return;
        }

        await demoRequestsRef
            .add(requestForm);

        setIsThankYouOpen(true);
    }

    if (isThankYouOpen) {
        return (
            <div style={{display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                <p style={{fontSize: 'calc(16px + 1vw)', fontWeight: 'bold'}}>
                    Thank you for submitting a demo request!
                </p>
                <p style={{fontSize: 'calc(12px + 0.5vw)'}}>
                    We will reach out to {requestForm.email} shortly to set up a time to meet with you!
                </p>
                <button onClick={handleCloseDemoRequest} type='button' style={{border: '1px solid grey', borderRadius: 5, padding: 5, width: '100px'}}>
                    Close
                </button>
            </div>
        )
    }

    return (
        <form className='demoFormContainer'>
            <p style={{fontSize: '20px', fontWeight: 'bold', paddingTop: '20px', marginBottom: 0}}>
                Request a Demo
            </p>
            <div className='demoNameContainer'>
                <div className='divider'>
                    <TextField className='width-250' label="First Name" name='firstName' value={requestForm.firstName} onChange={handleUpdateFormValue} style={{marginRight: 30}} />
                </div>
                <div className='divider'>
                    <TextField className='width-250' label="Last Name" name='lastName' value={requestForm.lastName} onChange={handleUpdateFormValue} />
                </div>
            </div>

            <div className='divider'>
                <TextField label="Your Role (eg. Owner, Manager, etc.)" name='role' value={requestForm.role} onChange={handleUpdateFormValue} style={{flex: 1}} />
            </div>
            <div className='divider'>
                <TextField label="Email" name="email" value={requestForm.email} onChange={handleUpdateFormValue} style={{flex: 1}} />
            </div>
            <div className='divider'>
                <TextField label="Gym Name" name="gymName" value={requestForm.gymName} onChange={handleUpdateFormValue} style={{flex: 1}} />
            </div>
            <div className='divider'>
                <TextField label="Gym Location" name="gymLocation" value={requestForm.gymLocation} onChange={handleUpdateFormValue} style={{flex: 1}} />
            </div>
            <div className='divider'>
                <TextField label="Comments" name="comments" value={requestForm.comments} onChange={handleUpdateFormValue} style={{flex: 1}} />
            </div>

            <div style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '50px' }}>
                <button onClick={handleSubmitRequestDemo} type='button' style={{border: '1px solid #e8e8e8', borderRadius: 5, padding: 5, width: '100px', backgroundColor: Colors.MUSTARD}}>
                    Submit
                </button>
            </div>
        </form>
    )
}

export default RequestDemoPage;
