import React from 'react';

import './BottomBanner.css';

import { Colors } from '../../../constants/Colors';

const BottomBanner = () => {

    return (
        <div style={{display: 'flex', backgroundColor: Colors.OFFWHITE, alignItems: 'center', justifyContent: 'center', height: '5vh', width: '100%'}}>
            <p style={{color: Colors.DRKGREY, margin: 0}}>
                Copyright @ 2021 Climb Time LLC
            </p>
            <a href="privacy-policy" style={{marginLeft: 40, color: Colors.DRKGREY, textDecorationLine: 'underline'}}>
                Privacy Policy
            </a>
        </div>
    )
}

export default BottomBanner;
